<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-19 16:41:33
 * @LastEditTime: 2021-01-29 10:29:44
 * @LastEditors: yangliao
 * @Description: 用户登录
 * @FilePath: /netHallOfficialAccounts/src/views/UserLogin/index.vue
-->
<template>
    <div class="userLogin-page">
        <div class="userLogin-title">
            <p>短信验证</p>
        </div>

        <div class="userLogin-content">
            <van-form>
                <van-field
                    label="手机号"
                    placeholder="水司预留手机号"
                    v-model="phoneNumber"
                    clearable
                    type="digit"
                    :rules="[{ validator: validatorPhone, message: '' }]"
                />
                <van-field label="验证码" placeholder="填写验证码" center clearable v-model="sms">
                    <template #button>
                        <van-button size="mini" type="default" class="sms-button" @click="getSendMessage" :disabled="isDisabled">{{buttonContent}}</van-button>
                    </template>
                </van-field>
                <p>人脸识别</p>
            </van-form>
        </div>
        <div class="nextBtn">
            <van-button type="info" block >下一步</van-button>
        </div>
    </div>
</template>

<script>
import { isMobile } from '@/utils/validator';
export default {
    name: 'UserLogin',
    data() {
        return {
            sms:'',
            isDisabled: false,
            buttonContent: '获取验证码',
            wait: 60,
            phoneNumber: ''
        }
    },
    mounted() {

    },
    methods: {
        validatorPhone(val) {
            return isMobile(val);
        },
        getSendMessage() {
            if (this.wait === 0) {
                this.isDisabled = false
                this.buttonContent = "获取验证码";
                this.wait = 60;
            } else {
                this.isDisabled = true
                this.buttonContent = this.wait + "s后重新获取";
                this.wait--;
                setTimeout(() => {
                    this.getSendMessage();
                }, 1000);
            }
        },
    }
}
</script>

<style lang="less" scoped>
.userLogin-page {
    background: #ffffff;
    .userLogin-title {
        p {
            margin: 0;
            padding-top: 33px;
            padding-bottom: 36px;
            padding-left: 16px;
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #1C2734;
            line-height: 26px;
            font-weight: 700;
        }
    }
    .userLogin-content {
        .sms-button {
            color: #999999;
            border: 1px solid #999999;
            border-radius: 100px;
            color: #4466AD;
            border: 1px solid #4466AD;
            .van-button__text {
                font-size: 12px;
            }
        }
        .van-button {
            border-radius: 4px;
        }
        /deep/.van-cell__title {
            span {
                font-family: PingFangSC-Regular;
                font-size: 16px;
                color: #333333;
            }
        }
        /deep/.van-field__control {
            display: inline-block;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #5E6268;
        }
        /deep/.van-field__right-icon {
            font-size: 16px;
        }
        p {
            margin: 0px;
            padding-left: 16px;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #4466AD;
        }
    }
    .nextBtn {
        margin-top: 135px;
        padding-bottom: 149px;
        margin-left: 16px;
        margin-right: 16px;
        .van-button {
            border-radius: 8px;
        }
    }
}
</style>
